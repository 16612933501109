<template>
  <div
    class="regular-text two-columns-text"
    :class="themeColorClass"
    :style="cssAlignment"
  >
    <UiHeader
      v-if="header"
      :header="header"
      :subheader="subheader"
      :header-layout="headerLayout"
      :header-position="headerPosition"
      :header-size="headerSize"
      :theme-color="themeColor"
    />

    <T3HtmlParser :content="bodytext" class="two-columns-text__wrapper" />

    <MacoButton :button="button" :lang="lang" />
  </div>
</template>

<script setup lang="ts">
import type { UiCeTextProps } from '~/types'
import { MacoButton, UiHeader } from '~ui/components'
import useHeaderAlignment from '~ui/composables/useHeaderAlignment'
import useTheming from '~ui/composables/useTheming'

const props = defineProps<UiCeTextProps>()

const themeColorClass = useTheming(props.themeColor)
const cssAlignment = useHeaderAlignment(props)
</script>

<style lang="scss">
.two-columns-text {
  &__wrapper {
    @include media-query(sm) {
      column-count: 2;
      column-gap: rem(35px);
      column-fill: balance;

      & p {
        break-inside: avoid;
      }
    }
  }
}
</style>
